import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import SectionCTA from "../components/section-cta"
import Illu from "../components/illu"
import { Link } from "gatsby"
import slugify from "../utils/slugify"

const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}

const SinglePost = ({ data }) => {

  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark
  const allPostsExceptMe = allMarkdownRemark.edges.filter(({node}) => node.fileAbsolutePath !== markdownRemark.fileAbsolutePath)
  const randomPost = allPostsExceptMe[getRandomInt(allPostsExceptMe.length - 1)].node
  
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        image={frontmatter.featuredImage.childImageSharp.resize.src}
      />
      <div className="section">
        <div className="container has-text-centered">
          <Illu post={markdownRemark} critical />
        </div>
      </div>
      <Link
        className="button has-text-weight-bold is-dark is-fullwidth"
        style={{ borderRadius: 0 }}
        to={"/illu/" + randomPost.frontmatter.date + "/" + slugify(randomPost.frontmatter.title)}>
        <span className="icon" style={{ filter: "grayscale(1)" }}>&#128256;</span> <span>Une autre</span>
      </Link>
      <SectionCTA />

      <div class="section is-medium">
        <div class="container has-text-centered">
          <h2 class="title pb-6 is-size-4-mobile">Autres Illustrations</h2>
          <div className="columns is-multiline is-mobile">
            {allMarkdownRemark.edges.map(({ node }) => (
              <div className="column is-2-tablet is-4-mobile is-vcentered">
                <Link to={"/illu/" + node.frontmatter.date + "/" + slugify(node.frontmatter.title)}>
                  <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query($fileAbsolutePath: String!) {
      markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath } ) {
        html
        fileAbsolutePath
        frontmatter {
          youtubePlayButton
          date(formatString: "YYYY-MM-DD")
          title        
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1344, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
              resize(width: 900, quality: 90) {
                src
              }
            }
          }
        }
      }
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 100
        filter: {isFuture: {eq: false}}
      ) {
        edges {
          node {
            isFuture
            fileAbsolutePath
            frontmatter {
              title
              date(formatString: "YYYY-MM-DD")  
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 250, quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  export default SinglePost